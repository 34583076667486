import React, { useEffect, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { deliveryChallanColumn } from '../ConstantTables';
import { Box, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddDeliveryChallan from './AddDeliveryChallan';
import { getDeliveryChallan } from '../../utils/api.delivery.challan';
import ProductDetailsOfDC from './ProductDetailsOfDC';
import { useCompanyContext } from '../../common/companyContext';

function DeliveryChallan() {
    const gridRef = useRef();
    const { selectedCompany } = useCompanyContext();
    const delivery_challan_column = deliveryChallanColumn()
    const [delivery_challans, set_delivery_challans] = useState([])
    const [is_open_delivery_model, set_is_open_delivery_model] = useState(false)
    const [on_search_text, setOnSearchText] = useState('')
    const [is_show_complete_details, set_is_show_complete_details] = useState(false)
    const [selected_row, set_selected_row] = useState('')
    const [is_success_response, setIsSuccessResponse] = useState(false)

    useEffect(() => {
        (async () => {
            await getAllDeliveryChallanList()
        })()
    }, [selectedCompany])

    useEffect(() => {
        (async () => {
            if (is_success_response === true) {
                await getAllDeliveryChallanList()
                setIsSuccessResponse(false)
            }
        })()
    }, [is_success_response])

    const getAllDeliveryChallanList = async () => {
        try {
            const response = await getDeliveryChallan();
            if (response?.data?.data) {
                set_delivery_challans(response?.data?.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onShowDetailsData = async (data) => {
        try {
            set_selected_row(data.data)
            set_is_show_complete_details(true)
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            <Box>
                <AddDeliveryChallan open_model={is_open_delivery_model} setOpenModel={set_is_open_delivery_model} setSuccessResponse={setIsSuccessResponse} />
            </Box>
            <Box>
                <ProductDetailsOfDC open_model={is_show_complete_details} setOpenModel={set_is_show_complete_details} selected_row={selected_row} />
            </Box>
            <main className="m-a-20">
                <div>
                    <div className='d-flex justify-end'>
                        <div className='d-flex justify-between w-100'>
                            <TextField className='w-25' label="Search By Name, Phone, Vehicle No." variant="outlined" onInput={(e) => setOnSearchText(e.target.value)} />
                            <Button className='black' startIcon={<AddIcon />} onClick={() => set_is_open_delivery_model(true)}>Add Delivery Challan</Button>
                        </div>
                    </div>
                </div>

                <div
                    style={{ height: '100%', width: '100%' }}
                    className="ag-theme-alpine m-t-20">
                    <AgGridReact
                        ref={gridRef}
                        rowData={delivery_challans}
                        columnDefs={delivery_challan_column}
                        pagination={true}
                        quickFilterText={on_search_text}
                        domLayout={'autoHeight'}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                        onRowClicked={(row) => onShowDetailsData(row)}
                        getRowStyle={(row) => ({ cursor: 'pointer' })}
                    />
                </div>
            </main>
        </div>
    )
}

export default DeliveryChallan