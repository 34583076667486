import React, { useCallback, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FaDownload } from "react-icons/fa";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDispatchLogData, getDispatchSummaryData, getProductionLogCsv, getProductionLogData, getProductionSummaryCsv, getProductionSummaryData, getWarehouseLogCsv, getWarehouseLogData, getWarehouseSummaryCsv, getWarehouseSummaryData, getDispatchLogCsv, getDispatchSummaryCsv, getDispatchVerificationReport } from '../../utils/api.report';
import { Box } from '@mui/material';
import { useCompanyContext } from '../../common/companyContext';
import { dispatchLogColumn, dispatchSummaryColumn, productionLogColumn, productionSummaryColumn, verificationReportColumn, warehouseLogColumn, warehouseLogColumnWithQRcode, warehouseSummaryColumn } from '../ConstantTables';
import { endTimeDateFormate, startTimeDateFormate } from '../../utils/functions';
import { errorMessage } from '../../common/Toast';
import { useLocation } from 'react-router-dom';
import ImageViewer from './ImageViewer';
import ShowVerificationReportDetails from './ShowVerificationReportDetails';
import RefreshIcon from '@mui/icons-material/Refresh';

function ReportResult(props) {
    const gridRef = useRef();
    const location = useLocation()
    const query_data = location?.state ? location?.state : props;
    const pagination_page_size = 50
    const { selectedCompany } = useCompanyContext();
    const [column, setColumn] = useState([])
    const [images_urls, setImagesUrls] = useState([])
    const [is_show_image_viewer, setIsShowImageViewer] = useState(false)
    const [is_show_complete_details, set_is_show_complete_details] = useState(false)
    const [selected_row, set_selected_row] = useState('')

    const datasource = {
        async getRows(params) {
            let response;
            const { startRow, endRow } = params.request;
            const pageSize = endRow - startRow;
            const pageIndex = Math.floor(startRow / pageSize) + 1;
            const query_data_object = {
                start_date: startTimeDateFormate(query_data.start_date),
                end_date: endTimeDateFormate(query_data.end_date),
                shift: query_data.shift,
                line: query_data.line,
                product_name: query_data.product_name,
                sku_size: query_data.sku_size,
                company_name: selectedCompany,
                page_size: pageSize,
                page_index: pageIndex
            }

            try {
                if (query_data.process_name === "Production" && query_data.report_name === "Production Log") {
                    response = await getProductionLogData(query_data_object);
                    const total_row_count = response?.data?.data?.total_count ? response?.data?.data?.total_count : 0
                    setColumn(productionLogColumn(setImagesUrls, setIsShowImageViewer, total_row_count))
                } else if (query_data.process_name === "Production" && query_data.report_name === "Production Summary") {
                    response = await getProductionSummaryData(query_data_object);
                    setColumn(productionSummaryColumn)
                } else if (query_data.process_name === "Warehouse" && query_data.report_name === "Warehouse Log") {
                    query_data_object.is_qr_read = query_data.is_qr_read
                    response = await getWarehouseLogData(query_data_object);
                    query_data.is_qr_read ? setColumn(warehouseLogColumnWithQRcode(setImagesUrls, setIsShowImageViewer)) : setColumn(warehouseLogColumn)
                } else if (query_data.process_name === "Warehouse" && query_data.report_name === "Warehouse Summary") {
                    query_data_object.is_qr_read = query_data.is_qr_read
                    response = await getWarehouseSummaryData(query_data_object);
                    setColumn(warehouseSummaryColumn)
                } else if (query_data.process_name === "Dispatch" && query_data.report_name === "Dispatch Log") {
                    response = await getDispatchLogData(query_data_object);
                    setColumn(dispatchLogColumn(setImagesUrls, setIsShowImageViewer))
                } else if (query_data.process_name === "Dispatch" && query_data.report_name === "Dispatch Summary") {
                    response = await getDispatchSummaryData(query_data_object);
                    setColumn(dispatchSummaryColumn)
                } else if (query_data.process_name === "Dispatch" && query_data.report_name === "Verification Report") {
                    response = await getDispatchVerificationReport(query_data_object);
                    setColumn(verificationReportColumn)
                }
                if (!response.data.data.scan_data.length)
                    params.api.showNoRowsOverlay()
                // console.log(response.data.data.scan_data, +response.data.data.total_count);
                // params.successCallback(response.data.data.scan_data, +response.data.data.total_count)
                params.success({
                    rowData: response.data.data.scan_data,
                    rowCount: +response.data.data.total_count,
                });
            } catch (error) {
                // params.failCallback()
                params.fail();
                errorMessage("Error fetching or processing data")
            }
        }
    };

    const onDownloadReportCsv = async () => {
        let response;
        const query_data_object = {
            start_date: startTimeDateFormate(query_data.start_date),
            end_date: endTimeDateFormate(query_data.end_date),
            shift: query_data.shift,
            line: query_data.line,
            product_name: query_data.product_name,
            sku_size: query_data.sku_size,
            is_qr_read: query_data.is_qr_read,
            company_name: selectedCompany
        }

        switch (true) {
            case query_data.process_name === "Production" && query_data.report_name === "Production Log":
                response = await getProductionLogCsv(query_data_object)
                break;
            case query_data.process_name === "Production" && query_data.report_name === "Production Summary":
                response = await getProductionSummaryCsv(query_data_object)
                break;
            case query_data.process_name === "Warehouse" && query_data.report_name === "Warehouse Log":
                response = await getWarehouseLogCsv(query_data_object)
                break;
            case query_data.process_name === "Warehouse" && query_data.report_name === "Warehouse Summary":
                response = await getWarehouseSummaryCsv(query_data_object)
                break;
            case query_data.process_name === "Dispatch" && query_data.report_name === "Dispatch Log":
                response = await getDispatchLogCsv(query_data_object);
                break;
            case query_data.process_name === "Dispatch" && query_data.report_name === "Dispatch Summary":
                response = await getDispatchSummaryCsv(query_data_object);
                break;
            default:
                break;
        }
        if (response?.data) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = query_data.report_name.toLowerCase();
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }

    const onGridReady = useCallback((params) => {
        params.api.setServerSideDatasource(datasource);
    }, [])

    const onHandleReturnPage = () => {
        if (query_data.is_home_report_route_back) {
            query_data.set_is_show_result_table(false)
        } else {
            window.history.back()
        }
    }

    const onShowDetailsData = async (data) => {
        try {
            set_selected_row(data.data)
            set_is_show_complete_details(true)
        } catch (error) {
            console.log(error);
        }
    }

    const refreshCache = useCallback((route) => {
        gridRef.current.api.refreshServerSide({ route: route });
    }, []);

    return (
        <div>
            <ImageViewer
                open={is_show_image_viewer}
                setOpen={setIsShowImageViewer}
                images_list={images_urls}
            />
            <Box>
                <ShowVerificationReportDetails open_model={is_show_complete_details} setOpenModel={set_is_show_complete_details} selected_row={selected_row} />
            </Box>
            <Box>
                <div className='d-flex justify-between'>
                    <div className='d-flex p-l-40 g-5'>
                        <ArrowBackIcon className='cursor' onClick={onHandleReturnPage} />
                        <h4 className='p-l-10'>{query_data.report_name}</h4>
                        {/* <RefreshIcon className='cursor m-l-10' onClick={() => refreshCache(undefined)} /> */}
                    </div>
                    <div className='m-r-30 cursor' onClick={onDownloadReportCsv}><FaDownload /> Download Report In CSV</div>
                </div>
            </Box>
            <Box
                style={{ height: '70%', padding: '10px 30px' }}
                className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    columnDefs={column}
                    pagination={true}
                    paginationPageSize={10}
                    domLayout="autoHeight"
                    rowModelType="serverSide"
                    onGridReady={onGridReady}
                    cacheBlockSize={pagination_page_size}
                    overlayNoRowsTemplate={'<b>No Row To Display</b>'}
                    paginationPageSizeSelector={[10, 25, 50, 100]}
                    onRowClicked={(params) => {
                        if (query_data.report_name === "Verification Report") {
                            onShowDetailsData(params)
                        }
                    }}
                    getRowStyle={(params) => {
                        if (query_data.report_name === "Verification Report") {
                            return { cursor: 'pointer' }
                        }
                    }}
                />
            </Box>
        </div>
    )
}

export default ReportResult